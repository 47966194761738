import React from 'react';
import { Pagination as Element } from 'react-bootstrap';
import { GenericItem } from 'lib/types';
import ListResponse from '../common/ListResponse';

/**
 * Gereric pagination for ListResponse
 */
interface PaginationProps<T extends GenericItem> {
    /**
     * Number of items to the left and right of the center page.
     *
     * @var {number}
     */
    offset: number;

    /**
     * Event fires when a page button is clicked.
     *
     * @var {ListResponse<T>}
     */
    pageClickedEvent: (page: number) => void;

    /**
     * Used to calculate startPage and endPage constants.
     *
     * @var {ListResponse<T>}
     */
    response: ListResponse<T>;
}

/**
 * Renders a pager with given list response.
 *
 * @param {number} offset Allows page items to the left and right of center page.
 * @param {CallableFunction} pageClickedEvent Event fires when a page is clicked.
 * @param {ListResponse<T>} response ListResponse for pager.
 *
 * @return {React.ReactElement}
 */
export default function Pagination<T extends GenericItem>({
    offset = 5,
    pageClickedEvent,
    response
}: PaginationProps<T>): React.ReactElement {
    /**
     * Total number of pages.  Does not include Previous or Next.
     *
     * @var {number}
     */
    const totalPages: number = Math.ceil(response.totalItems / response.itemsPerPage);

    /**
     * First page number.  Does not include Previous.
     *
     * @var {number}
     */
    const startPage: number = response.page - offset > 1 ? response.page - offset : 1;

    /**
     * Last page number.  Does not include Next.
     *
     * @var {number}
     */
    const endPage: number = response.page + offset > totalPages ? totalPages : response.page + offset;

    /**
     * Initalize array to hold pagination items (react elements).
     *
     * @var {Array<React.ReactElement>}
     */
    const items: React.ReactElement[] = new Array<React.ReactElement>();

    for (let number: number = startPage - 1; number <= endPage + 1; number++) {
        let item: React.ReactElement;

        if (number === startPage - 1) {
            item = <Element.Prev key={number} disabled={number === 0} onClick={(): void => pageClickedEvent(number)} />;
        } else if (number === endPage + 1) {
            item = (
                <Element.Next
                    key={number}
                    disabled={number === totalPages + 1}
                    onClick={(): void => pageClickedEvent(number)}
                />
            );
        } else {
            item = (
                <Element.Item
                    key={number}
                    active={number == response.page}
                    onClick={(): void => pageClickedEvent(number)}
                >
                    {number}
                </Element.Item>
            );
        }

        items.push(item);
    }

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return <Element>{items}</Element>;
}
