import React, { useState, useEffect } from 'react';
import { Link as rLink, NavigateFn } from '@reach/router';
import { getResourceItem, policyLibraryApp } from 'lib/sdk';
import { Link } from 'lib/components';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ListResponse, { EmptyListResponse } from '../common/ListResponse';
import { GenericItem, SearchDoc, SluggableItem } from 'lib/types';
import ListGroup from '../components/ListGroup';
import Pagination from '../components/Pagination';
import RequestState from '../common/RequestState';
import { graphql, StaticQueryDocument, navigate } from 'gatsby';
import Layout from '../components/layout';

/**
 * Properties for search.
 */
interface SearchProps {
    /**
     * What user is searching.
     *
     * @var {string}
     */
    query: string;

    /**
     * Page number of request.
     *
     * @var {number}
     */
    page: number;
}

const query: StaticQueryDocument = graphql`
    query localesSearch {
        locales: allLocale(filter: { ns: { in: ["general", "policy-library"] } }) {
            edges {
                node {
                    language
                    data
                    ns
                }
            }
        }
    }
`;

/**
 * Renders a page for search documents.
 *
 * @param {string} query Search terms from search request.
 * @param {number} page Page number of search request.
 *
 * @return {React.ReactElement}
 */
function Search({ query = '*', page = 1 }: SearchProps): React.ReactElement {
    /**
     * Search response.
     *
     * @var {ListResponse<SearchDoc>}
     */
    const [response, setResponse] = useState<ListResponse<SearchDoc>>(new EmptyListResponse<SearchDoc>());

    /**
     * Current request state.
     *
     * @var {RequestState}
     */
    const [state, setState] = useState(RequestState.Unknown);

    /**
     * Renders a list link.
     *
     * @param {SearchDoc} item Search Doc to render.
     *
     * @return {React.ReactElement}
     */
    function renderListLink(item: SearchDoc): React.ReactElement {
        const manual: SluggableItem | null = item.topManual ? item.topManual : null;
        let message: React.ReactElement = <div>This manual has been retired</div>;

        if (manual) {
            message = (
                <div>
                    <h5 className='mb-1'>
                        <Link to={`/manual/${manual.slug}`} icon={faAngleRight} routerLink={rLink}>
                            {item.name}
                        </Link>
                    </h5>
                    <small className='mb-2'>
                        <p>{manual.name}</p>
                    </small>
                    {item.description ? <p dangerouslySetInnerHTML={{ __html: item.description }} /> : ' '}
                </div>
            );
        }

        return message;
    }

    /**
     * Navigates to given search page.
     *
     * @param {number} pageNumber Page of search
     *
     * @return {void}
     */
    function pageClicked(pageNumber: number): void {
        document.body.scrollTop = 0;
        navigate(`/search/${query}/${pageNumber}`);
    }

    /**
     * Updates UI
     *
     * @return {void}
     */
    useEffect(() => {
        /**
         * Important!: Reach router always passes in the page argument
         * as a string despite it is defined as a number.
         */
        const requestPage: number = parseInt(page.toString());

        // Do not call the api on initial load or when the search request changes.
        if (state === RequestState.Unknown || response.page != requestPage || response.query != query) {
            setState(RequestState.Waiting);

            (async (): Promise<void> => {
                const results: ListResponse<SearchDoc> | null = await getResourceItem<ListResponse<SearchDoc>>(
                    `/search/${query}?page=${requestPage}`,
                    policyLibraryApp
                );

                if (results) {
                    results.query = query;
                    setResponse(results);
                }
            })();

            setState(RequestState.Loaded);
        }
    });

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Layout>
            <div className='d-block d-sm-flex justify-content-between'>
                <h6>Results For: {query}</h6>
                <h6>
                    Showing {(response.page - 1) * response.itemsPerPage} to{' '}
                    {(response.page - 1) * response.itemsPerPage + response.results.length} of {response.totalItems}
                </h6>
            </div>
            <ListGroup isFlush={true} items={response.results} renderItem={renderListLink} />
            <Pagination<GenericItem> offset={5} pageClickedEvent={pageClicked} response={response} />
        </Layout>
    );
}

export { Search as default, query };
