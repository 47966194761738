import { GenericItem } from 'lib/types';

/**
 * Generic response for a list request.
 */
interface ListResponse<T extends GenericItem> {
    /**
     * Total number of items that can be searched.
     *
     * @var {number}
     */
    totalItems: number;

    /**
     * Amount of items in page request.
     *
     * @var {number}
     */
    itemsPerPage: number;

    /**
     * Page number of request
     *
     * @var {number}
     */
    page: number;

    /**
     * Query string params of request
     *
     * @var {any}
     */
    query: any;

    /**
     * Search documents
     *
     * @var {Array<T>}
     */
    results: T[];
}

export default ListResponse;

/**
 * Empy list response.
 *
 * @var {EmptyListResponse<T>}
 */
export class EmptyListResponse<T extends GenericItem> implements ListResponse<T> {
    /**
     * Total number of items in query
     *
     * @var {number}
     */
    totalItems: number = 0;

    /**
     * Number of items in per page request
     *
     * @var {number}
     */
    itemsPerPage: number = 0;

    /**
     * Current page request
     *
     * @var {number}
     */
    page: number = 0;

    /**
     * Query string params of request
     *
     * @var {any}
     */
    query: any = null;

    /**
     * Items
     *
     * @var {Array<T>}
     */
    results: T[] = new Array<T>();
}
